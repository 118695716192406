var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor-panel" },
    [
      _c("vx-tour", {
        attrs: { name: "questionnaireEditorIntroSteps", auto: true },
      }),
      _c("div", { staticClass: "flex mb-4 items-center justify-center" }, [
        _c("div", { staticClass: "grid grid-cols-12 w-full" }, [
          _vm.questionsLoaded
            ? _c(
                "div",
                {
                  staticClass: "col-span-3 relative lg:col-span-2",
                  staticStyle: { "z-index": "4" },
                },
                [
                  _c("div", { staticClass: "sticky topx" }, [
                    _c(
                      "div",
                      {
                        ref: "editorPanel",
                        class: _vm.cardClass,
                        staticStyle: { "z-index": "1" },
                        attrs: { id: "editor-panel" },
                      },
                      [
                        _c("vs-card", [
                          _c(
                            "div",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-xs sm:text-sm md:text-base lg:text-base xl:text-base",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.expandedItems
                                          ? "PAINEL DE ITENS"
                                          : "PAINEL"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            [
                              _c("vs-button", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showExpandButton(),
                                    expression: "showExpandButton()",
                                  },
                                ],
                                staticClass: "min-w-full pb-2 panel-item-btn",
                                staticStyle: { "justify-content": "center" },
                                attrs: { color: "primary", icon: "menu" },
                                on: {
                                  click: function ($event) {
                                    return _vm.expandItems()
                                  },
                                },
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.expandedItems,
                                      expression: "expandedItems",
                                    },
                                  ],
                                },
                                [
                                  _vm._l(_vm.dragItems, function (item) {
                                    return _c(
                                      "drag",
                                      {
                                        key: item.id,
                                        staticClass: "pb-2",
                                        attrs: { data: item },
                                      },
                                      [
                                        _vm.showButtonText
                                          ? _c(
                                              "vs-button",
                                              {
                                                staticClass:
                                                  "min-w-full panel-item-btn cursor-move",
                                                staticStyle: {
                                                  "justify-content":
                                                    "flex-start",
                                                },
                                                attrs: {
                                                  color: "primary",
                                                  type: "border",
                                                  icon: item.icon,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "text-xs" },
                                                  [_vm._v(_vm._s(item.name))]
                                                ),
                                              ]
                                            )
                                          : _c("vs-button", {
                                              staticClass:
                                                "min-w-full panel-item-btn  cursor-move",
                                              attrs: {
                                                color: "primary",
                                                type: "border",
                                                icon: item.icon,
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "vs-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value:
                                            "questionnaires.question.import",
                                          expression:
                                            "'questionnaires.question.import'",
                                        },
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.top-end",
                                          value: {
                                            content: _vm.$t("action.import"),
                                            delay: { show: 500, hide: 100 },
                                          },
                                          expression:
                                            "{content: $t('action.import'),delay: { show: 500, hide: 100 },}",
                                          modifiers: { "top-end": true },
                                        },
                                      ],
                                      staticClass: "min-w-full panel-item-btn",
                                      attrs: {
                                        color: "primary",
                                        type: "border",
                                        "icon-pack": "feather",
                                        icon: "icon-file-plus",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.showQuestionImportPopup = true
                                        },
                                      },
                                    },
                                    [
                                      _vm.showButtonText
                                        ? _c(
                                            "span",
                                            { staticClass: "text-xs" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("action.import"))
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm.expandedItems
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center justify-end pt-1",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "absolute inset-preview-button",
                                    },
                                    [
                                      _c("vs-button", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip.top-end",
                                            value: {
                                              content: "Pré-visualizar",
                                              delay: { show: 500, hide: 100 },
                                            },
                                            expression:
                                              "{\n                      content: 'Pré-visualizar',\n                      delay: { show: 500, hide: 100 },\n                    }",
                                            modifiers: { "top-end": true },
                                          },
                                        ],
                                        attrs: {
                                          color: "primary",
                                          radius: "",
                                          icon: "remove_red_eye",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.preview()
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "vs-row",
                                    { attrs: { "vs-justify": "flex-end" } },
                                    [
                                      _c("vs-button", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip.top-end",
                                            value: {
                                              content: "Pré-visualizar",
                                              delay: { show: 500, hide: 100 },
                                            },
                                            expression:
                                              "{\n                      content: 'Pré-visualizar',\n                      delay: { show: 500, hide: 100 },\n                    }",
                                            modifiers: { "top-end": true },
                                          },
                                        ],
                                        attrs: {
                                          color: "primary",
                                          radius: "",
                                          icon: "remove_red_eye",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.preview()
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "col-span-9 lg:col-span-10" },
            [
              _vm.questions.length === 0 && _vm.questionsLoaded
                ? _c(
                    "div",
                    {
                      staticClass: "text-center mt-4",
                      attrs: { id: "tour-editor-drop" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("insira-as-questoes-aqui-abaixo")) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.questionsLoaded
                ? _c(
                    "div",
                    {
                      staticClass: "text-center mt-4",
                      attrs: { id: "tour-editor-drop" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("carregando-questoes-aguarde")) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "drop-list",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.questionsLoaded,
                      expression: "questionsLoaded",
                    },
                  ],
                  attrs: { items: _vm.questions },
                  on: {
                    reorder: function ($event) {
                      return $event.apply(_vm.questions)
                    },
                    insert: _vm.insert,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function (ref) {
                        var item = ref.item
                        var reorder = ref.reorder
                        return [
                          _c(
                            "drag",
                            {
                              key: item.id,
                              attrs: { data: item, handle: ".handle" },
                              on: {
                                cut: function ($event) {
                                  return _vm.remove(_vm.questions, item)
                                },
                                dragstart: function ($event) {
                                  return _vm.dragStart(item)
                                },
                                dragend: function ($event) {
                                  return _vm.dragEnd(item)
                                },
                              },
                            },
                            [
                              _c(
                                "vs-list",
                                {
                                  class: {
                                    "cursor-pointer":
                                      _vm.itemIsInvalidated(item),
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleItemClick(item)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c(
                                          "vs-list-item",
                                          {
                                            class: _vm.getItemClass(item),
                                            style: reorder
                                              ? {
                                                  borderLeft:
                                                    "2px solid #1976D2",
                                                  marginLeft: "-2px",
                                                }
                                              : {},
                                            attrs: { id: item.id + "-item" },
                                            on: {
                                              "item-DELETE_ITEM": function (
                                                $event
                                              ) {
                                                return _vm.deleteItem($event)
                                              },
                                              "item-DUPLICATE_ITEM": function (
                                                $event
                                              ) {
                                                return _vm.duplicateItem($event)
                                              },
                                              "item-ITEM_UPDATED": function (
                                                $event
                                              ) {
                                                return _vm.updateItem($event)
                                              },
                                              "item-INVALIDATE_ITEM": function (
                                                $event
                                              ) {
                                                return _vm.invalidateItem(
                                                  $event
                                                )
                                              },
                                              "item-REVALIDATE_ITEM": function (
                                                $event
                                              ) {
                                                return _vm.revalidateItem(
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "handle",
                                                on: {
                                                  mouseenter: function (
                                                    $event
                                                  ) {
                                                    return _vm.handleEnter(item)
                                                  },
                                                  mouseleave: function (
                                                    $event
                                                  ) {
                                                    return _vm.handleLeave(item)
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-list-item-avatar", [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            item.handle &&
                                                            !_vm.dragging,
                                                          expression:
                                                            "item.handle && !dragging",
                                                        },
                                                      ],
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: "grip-horizontal",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            item.type === "simple_text"
                                              ? _c("SimpleText", {
                                                  attrs: {
                                                    item: item,
                                                    parent_id: _vm.id,
                                                    disabled:
                                                      _vm.getItemDisability(
                                                        item
                                                      ),
                                                    showInvalidateButton:
                                                      _vm.showInvalidateQuestionButton,
                                                    cheetEnabled:
                                                      _vm.cheetEnabled,
                                                  },
                                                  on: {
                                                    questionRendered:
                                                      _vm.asyncLoadNextOption,
                                                  },
                                                })
                                              : _vm._e(),
                                            item.type === "multiple_choice"
                                              ? _c("MultipleChoice", {
                                                  attrs: {
                                                    item: item,
                                                    parent_id: _vm.id,
                                                    disabled:
                                                      _vm.getItemDisability(
                                                        item
                                                      ),
                                                    showInvalidateButton:
                                                      _vm.showInvalidateQuestionButton,
                                                    cheetEnabled:
                                                      _vm.cheetEnabled,
                                                  },
                                                  on: {
                                                    questionRendered:
                                                      _vm.asyncLoadNextOption,
                                                  },
                                                })
                                              : _vm._e(),
                                            item.type === "check_box"
                                              ? _c("CheckBox", {
                                                  attrs: {
                                                    item: item,
                                                    parent_id: _vm.id,
                                                    disabled:
                                                      _vm.getItemDisability(
                                                        item
                                                      ),
                                                    showInvalidateButton:
                                                      _vm.showInvalidateQuestionButton,
                                                    cheetEnabled:
                                                      _vm.cheetEnabled,
                                                  },
                                                  on: {
                                                    questionRendered:
                                                      _vm.asyncLoadNextOption,
                                                  },
                                                })
                                              : _vm._e(),
                                            item.type === "gap"
                                              ? _c("Gap", {
                                                  attrs: {
                                                    item: item,
                                                    parent_id: _vm.id,
                                                    disabled:
                                                      _vm.getItemDisability(
                                                        item
                                                      ),
                                                    showInvalidateButton:
                                                      _vm.showInvalidateQuestionButton,
                                                    cheetEnabled:
                                                      _vm.cheetEnabled,
                                                  },
                                                  on: {
                                                    questionRendered:
                                                      _vm.asyncLoadNextOption,
                                                  },
                                                })
                                              : _vm._e(),
                                            item.type === "section"
                                              ? _c("Section", {
                                                  attrs: {
                                                    item: item,
                                                    parent_id: _vm.id,
                                                  },
                                                  on: {
                                                    questionRendered:
                                                      _vm.asyncLoadNextOption,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c(
                                          "vs-dropdown",
                                          { staticClass: "flex float-right" },
                                          [
                                            _c("vs-button", {
                                              attrs: { icon: "add" },
                                            }),
                                            _c(
                                              "vs-dropdown-menu",
                                              {
                                                staticClass:
                                                  "newQuestionDropdown",
                                                staticStyle: { width: "200px" },
                                              },
                                              [
                                                _c(
                                                  "vs-dropdown-item",
                                                  [
                                                    _c(
                                                      "vs-button",
                                                      {
                                                        staticClass:
                                                          "p-2 w-full text-left",
                                                        attrs: {
                                                          type: "flat",
                                                          color: "primary",
                                                          icon: "view_stream",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleNewQuestion(
                                                              "section",
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "question_type.section"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "vs-dropdown-item",
                                                  [
                                                    _c(
                                                      "vs-button",
                                                      {
                                                        staticClass:
                                                          "p-2 w-full text-left",
                                                        attrs: {
                                                          type: "flat",
                                                          color: "primary",
                                                          icon: "edit",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleNewQuestion(
                                                              "simple_text",
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "question_type.simple_text"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "vs-dropdown-item",
                                                  [
                                                    _c(
                                                      "vs-button",
                                                      {
                                                        staticClass:
                                                          "p-2 w-full",
                                                        attrs: {
                                                          type: "flat",
                                                          color: "primary",
                                                          icon: "list",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleNewQuestion(
                                                              "multiple_choice",
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "question_type.multiple_choice"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "vs-dropdown-item",
                                                  [
                                                    _c(
                                                      "vs-button",
                                                      {
                                                        staticClass:
                                                          "p-2 w-full",
                                                        attrs: {
                                                          type: "flat",
                                                          color: "primary",
                                                          icon: "check_box",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleNewQuestion(
                                                              "check_box",
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "question_type.check_box"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "vs-dropdown-item",
                                                  [
                                                    _c(
                                                      "vs-button",
                                                      {
                                                        staticClass:
                                                          "p-2 w-full",
                                                        attrs: {
                                                          type: "flat",
                                                          color: "primary",
                                                          icon: "space_bar",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleNewQuestion(
                                                              "gap",
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "question_type.gap"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "feedback",
                      fn: function (ref) {
                        var data = ref.data
                        return [
                          _c(
                            "div",
                            { key: data, staticClass: "item feedback" },
                            [
                              data.type === "section"
                                ? _c(
                                    "vue-content-loading",
                                    { attrs: { width: 300, height: 7 } },
                                    [
                                      _c("rect", {
                                        attrs: {
                                          x: "0",
                                          y: "2",
                                          rx: "4",
                                          ry: "4",
                                          width: "300",
                                          height: "5",
                                        },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "vue-content-loading",
                                    { attrs: { width: 300, height: 32 } },
                                    [
                                      _c("rect", {
                                        attrs: {
                                          x: "3",
                                          y: "5",
                                          rx: "4",
                                          ry: "4",
                                          width: "100",
                                          height: "7",
                                        },
                                      }),
                                      _c("rect", {
                                        attrs: {
                                          x: "3",
                                          y: "15",
                                          rx: "4",
                                          ry: "4",
                                          width: "50",
                                          height: "7",
                                        },
                                      }),
                                      _c("rect", {
                                        attrs: {
                                          x: "3",
                                          y: "25",
                                          rx: "4",
                                          ry: "4",
                                          width: "75",
                                          height: "7",
                                        },
                                      }),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("div", { staticStyle: { height: "200px" } })]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "status-bar grid grid-cols-1 fixed bottom-0 ...",
          style: _vm.statusBarStyle,
        },
        [
          _c("vs-card", [
            _c("div", { staticClass: "flex justify-between gap-2" }, [
              _c("div", [
                _c("div", { staticClass: "grid grid-cols-1 ml-4" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-bold flex items-center justify-center primary-color",
                    },
                    [_vm._v(_vm._s(_vm.$t("questoes")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex items-center justify-center font-bold",
                    },
                    [_vm._v(" " + _vm._s(_vm.questionCount) + " ")]
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "grid grid-cols-1" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-bold flex items-center justify-center primary-color",
                    },
                    [_vm._v(_vm._s(_vm.$t("points")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex items-center justify-center font-bold",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.totalScore) +
                          " / " +
                          _vm._s(
                            _vm.content.average_score
                              ? _vm.content.average_score
                              : "-"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("div", [
                _c("div", { staticClass: "grid grid-cols-1" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-bold flex items-center justify-center primary-color",
                    },
                    [_vm._v(_vm._s(_vm.$t("media")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex items-center justify-center font-bold",
                    },
                    [_vm._v(" " + _vm._s(_vm.calcAverageScore()) + " ")]
                  ),
                ]),
              ]),
              _vm.hasQuestionDuration
                ? _c("div", [
                    _c("div", { staticClass: "grid grid-cols-1" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.auto",
                              value: {
                                content: "Soma de Durações por Questão",
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              },
                              expression:
                                "{\n                content: 'Soma de Durações por Questão',\n                delay: {\n                  show: 500, hide: 500\n                }\n              }",
                              modifiers: { auto: true },
                            },
                          ],
                          staticClass:
                            "font-bold flex items-center justify-center primary-color",
                        },
                        [_vm._v(" SDQ ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center font-bold",
                        },
                        [_vm._v(" " + _vm._s(_vm.totalQuestionsDuration) + " ")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.randomType
                ? _c("div", [
                    _c("div", { staticClass: "grid grid-cols-1" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.auto",
                              value: {
                                content: _vm.qqaTooltipContent,
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              },
                              expression:
                                "{\n                content: qqaTooltipContent,\n                delay: {\n                  show: 500, hide: 500\n                }\n              }",
                              modifiers: { auto: true },
                            },
                          ],
                          staticClass:
                            "font-bold flex items-center justify-center primary-color",
                        },
                        [_vm._v(" QQA ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center font-bold",
                        },
                        [_vm._v(" " + _vm._s(_vm.randomCount) + " ")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "flex items-center justify-center" }, [
                _c("div", { staticClass: "grid grid-cols-1 md:mr-6" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center justify-center primary-color font-bold",
                    },
                    [_vm._v(" " + _vm._s(_vm.updatedAt.value) + " ")]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          directives: [
            {
              name: "esc",
              rawName: "v-esc",
              value: _vm.closeBaseQuestionModal,
              expression: "closeBaseQuestionModal",
            },
          ],
          attrs: {
            title: _vm.$t("banco-de-questoes"),
            active: _vm.showBaseQuestionModal,
            fullscreen: true,
          },
          on: {
            "update:active": function ($event) {
              _vm.showBaseQuestionModal = $event
            },
          },
        },
        [
          _vm.showBaseQuestionModal
            ? _c("BaseQuestionModal", {
                on: { pressedAddQuestions: _vm.addSelectedBaseQuestions },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: "Integração OpenAI",
            active: _vm.showOpenAIModal,
            fullscreen: true,
          },
          on: {
            "update:active": function ($event) {
              _vm.showOpenAIModal = $event
            },
          },
        },
        [
          _vm.showOpenAIModal
            ? _c("OpenAIModal", {
                directives: [
                  {
                    name: "esc",
                    rawName: "v-esc",
                    value: _vm.closeOpenAIModal,
                    expression: "closeOpenAIModal",
                  },
                ],
                on: { pressedAddQuestions: _vm.addSelectedOpenAI },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticStyle: { "overflow-y": "auto", "z-index": "54000" },
          attrs: {
            title: _vm.$t("importacao"),
            active: _vm.showQuestionImportPopup,
          },
          on: {
            "update:active": function ($event) {
              _vm.showQuestionImportPopup = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "grid grid-cols-1 gap-4 pt-4" },
            [
              _c("content-file-upload", {
                attrs: {
                  uploadUrl: _vm.sheetUploadImportQuestionUrl,
                  autoProcessUpload: false,
                  maxFiles: 1,
                  customMessageSuccess: "iniciando-processamento-do-arquivo",
                  accept:
                    "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                },
                on: { input: _vm.successImportQuestion },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }