<template>
  <div id="divParent">
    <div class="flex">
      <div class="w-full">
        <vs-divider position="left" class="mt-2" color="primary" v-if="showIndex">
            {{ $t('level') }} : {{index+1}}
        </vs-divider>
      </div>
      <div class="flex justify-end max-w-50" v-if="showButtonDelete">
        <vs-button
            color="danger"
            type="flat"
            icon-pack="feather"
            icon="icon-trash"
            v-tooltip="{
              content: $t('action.delete'),
              delay: { show: 500, hide: 100 }
            }"
            @click="deleteScoreLevel()"></vs-button>
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
      <div class="flex">
        <vs-input
          class="w-full required"
          v-validate="'required|max:191'"
          :label="$t('level')"
          v-model="modelObject.name"/>
      </div>
      <div class="col-span-2 grid grid-cols-2 sm:grid-cols-2 md:md:grid-cols-1">
      <div class="flex">
        <vx-input-group>
          <vs-input
            lang="pt-BR"
            type="number"
            v-validate="'required|numeric'"
            :label="$t('min_score')"
            v-model="modelObject.min_score"
            @change="scoreChange"/>
            <template slot="prepend">
                 <div class="append-text btn-addon flex items-end">
                    <span class="input-group-text includeIcon includeIconOnly vs-component">&gt;=</span>
                  </div>
              </template>
        </vx-input-group>
      </div>
      <div class="flex">
        <vx-input-group>
          <vs-input
            lang="pt-BR"
            v-validate="'required|numeric'"
            :label="$t('max_score')"
            v-model="modelObject.max_score"
            @change="scoreChange"/>
            <template slot="prepend">
                 <div class="append-text btn-addon flex items-end">
                    <span class="input-group-text includeIcon includeIconOnly vs-component">&lt;</span>
                  </div>
              </template>
        </vx-input-group>
      </div>
      </div>
    </div>
    <div class="flex pt-2">
        <vs-textarea
          class="w-full"
          :label="$t('description')"
          v-model="modelObject.description"/>
          <div class="flex gap-2 items-center justify-end pl-5" v-if="showButtonAdd">
            <vs-button
              :disabled="!validateForm"
              color="primary"
              icon="add"
              v-tooltip="{
                  content: $t('adicionar'),
                  delay: { show: 500, hide: 100 }
                }"
              @click="addScoreLevel($event)">
            </vs-button>
          </div>
    </div>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    model: {
      default: null,
      type: Object
    },
    index: {
      default: 0,
      type: Number
    },
    showIndex: {
      default: true,
      type: Boolean
    },
    showButtonAdd: {
      default: false,
      type: Boolean
    },
    showButtonDelete: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    modelObject:{
      name: null,
      min_score: null,
      max_score : null,
      description: null
    },
    modelList: []
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(_.get(this.modelObject, 'name'))
              && !this.isEmpty(_.get(this.modelObject, 'min_score'))
              && !this.isEmpty(_.get(this.modelObject, 'max_score'))
    }
  },
  watch: {

  },
  beforeMount() {

  },
  mounted() {
    if(this.model){
      this.modelObject = this.model
    }
  },

  methods: {
    addScoreLevel(event){
      const modelCopy = this._.cloneDeep(this.modelObject)
      this.$emit('addScoreLevel', modelCopy)
      this.clearForm()
      this.scrollToBotton(event)
    },
    clearForm(){
      this.modelObject.name = ''
      this.modelObject.min_score = ''
      this.modelObject.max_score = ''
      this.modelObject.description = ''
    },
    deleteScoreLevel(){
      this.$emit('deleteScoreLevel', this.index)
    },
    scoreChange(event) {
      let score = event.target.value
      try{
          Number.parseFloat(score)
      }catch(err){
        event.target.value = 0
      }
      //
      if (score && Number.parseFloat(score) < 0) {
        event.target.value = 0
      }

      console.log('score changing...:'+event.target.value)
    },
    scrollToBotton(event) {
       var element = this.$el.parentElement.parentElement
      var top = 0

      top = element.scrollHeight

      setTimeout(() => {
        element.scrollTo(0, top)
      }, 500)
    },
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style lang="scss" scopped>
  .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #eee;
    border-radius: 5px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
</style>
