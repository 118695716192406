var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "divParent" } }, [
    _c("div", { staticClass: "flex" }, [
      _c(
        "div",
        { staticClass: "w-full" },
        [
          _vm.showIndex
            ? _c(
                "vs-divider",
                {
                  staticClass: "mt-2",
                  attrs: { position: "left", color: "primary" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("level")) +
                      " : " +
                      _vm._s(_vm.index + 1) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showButtonDelete
        ? _c(
            "div",
            { staticClass: "flex justify-end max-w-50" },
            [
              _c("vs-button", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      content: _vm.$t("action.delete"),
                      delay: { show: 500, hide: 100 },
                    },
                    expression:
                      "{\n            content: $t('action.delete'),\n            delay: { show: 500, hide: 100 }\n          }",
                  },
                ],
                attrs: {
                  color: "danger",
                  type: "flat",
                  "icon-pack": "feather",
                  icon: "icon-trash",
                },
                on: {
                  click: function ($event) {
                    return _vm.deleteScoreLevel()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2" },
      [
        _c(
          "div",
          { staticClass: "flex" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|max:191",
                  expression: "'required|max:191'",
                },
              ],
              staticClass: "w-full required",
              attrs: { label: _vm.$t("level") },
              model: {
                value: _vm.modelObject.name,
                callback: function ($$v) {
                  _vm.$set(_vm.modelObject, "name", $$v)
                },
                expression: "modelObject.name",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "col-span-2 grid grid-cols-2 sm:grid-cols-2 md:md:grid-cols-1",
          },
          [
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c(
                  "vx-input-group",
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|numeric",
                          expression: "'required|numeric'",
                        },
                      ],
                      attrs: {
                        lang: "pt-BR",
                        type: "number",
                        label: _vm.$t("min_score"),
                      },
                      on: { change: _vm.scoreChange },
                      model: {
                        value: _vm.modelObject.min_score,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelObject, "min_score", $$v)
                        },
                        expression: "modelObject.min_score",
                      },
                    }),
                    _c("template", { slot: "prepend" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon flex items-end" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "input-group-text includeIcon includeIconOnly vs-component",
                            },
                            [_vm._v(">=")]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c(
                  "vx-input-group",
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|numeric",
                          expression: "'required|numeric'",
                        },
                      ],
                      attrs: { lang: "pt-BR", label: _vm.$t("max_score") },
                      on: { change: _vm.scoreChange },
                      model: {
                        value: _vm.modelObject.max_score,
                        callback: function ($$v) {
                          _vm.$set(_vm.modelObject, "max_score", $$v)
                        },
                        expression: "modelObject.max_score",
                      },
                    }),
                    _c("template", { slot: "prepend" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon flex items-end" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "input-group-text includeIcon includeIconOnly vs-component",
                            },
                            [_vm._v("<")]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "flex pt-2" },
      [
        _c("vs-textarea", {
          staticClass: "w-full",
          attrs: { label: _vm.$t("description") },
          model: {
            value: _vm.modelObject.description,
            callback: function ($$v) {
              _vm.$set(_vm.modelObject, "description", $$v)
            },
            expression: "modelObject.description",
          },
        }),
        _vm.showButtonAdd
          ? _c(
              "div",
              { staticClass: "flex gap-2 items-center justify-end pl-5" },
              [
                _c("vs-button", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.$t("adicionar"),
                        delay: { show: 500, hide: 100 },
                      },
                      expression:
                        "{\n                content: $t('adicionar'),\n                delay: { show: 500, hide: 100 }\n              }",
                    },
                  ],
                  attrs: {
                    disabled: !_vm.validateForm,
                    color: "primary",
                    icon: "add",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addScoreLevel($event)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }