import Rest from '../Rest'

export default class OpenAIService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/openai'

  static build(vs: any = null, baseURL: any = null) {
    return new this(this.resource, null, vs, baseURL)
  }

  generateNewQuestion(params: any) {
    return this.post('/new_question', params).then(data => {
      return data
    })
  }

}
