<template>
  <div class="h-full">

    <label class="w-full text-left block"> Tipo de questão: </label>
    <div class="grid grid-cols-4">

      <vs-radio
        v-model="questionType"
        vs-name="sourcefilter"
        vs-value="simple_text"
        @input="selectedOption"
        class="mt-2">
          Texto
      </vs-radio>

      <vs-radio
        v-model="questionType"
        vs-name="sourcefilter"
        vs-value="multiple_choice"
        @input="selectedOption"
        class="mt-2">
          Múltipla Escolha
      </vs-radio>

      <vs-radio
        v-model="questionType"
        vs-name="sourcefilter"
        vs-value="check_box"
        @input="selectedOption"
        class="mt-2">
          Caixa de Seleção
      </vs-radio>

      <!-- <vs-radio
        v-model="questionType"
        vs-name="sourcefilter"
        vs-value="gap"
        @input="selectedOption"
        class="mt-2">
          Lacuna
      </vs-radio> -->

    </div>

    <div class="grid grid-cols-1 mt-4">

      <div class="grid grid-cols-4">

        <div>
          <label class="w-full text-left block"> Quantidade questões: </label>
          <vs-input
            v-model="questionsQty"
            name="questionsQty"
            type="number"
            v-mask="['###']"
          />
        </div>

        <div v-if="hasOptions">
          <label class="w-full text-left block"> Quantidade alternativas: </label>
          <vs-input
            v-model="optionsQty"
            name="optionsQty"
            type="number"
            v-mask="['#']"
          />
        </div>

        <div>

          <label class="w-full text-left block"> Nível de dificuldade: </label>
          <vs-slider v-model="temperature"></vs-slider>

        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 mt-4">
      <label class="w-full text-left block"> Descrição sobre o tema: </label>
      <vs-textarea
        id="prompt"
        ref="textarea"
        class="w-full"
        v-model="prompt"
        placeholder="Informe o tema aqui, pode usar uma breve descrição ou um texto mais complexo. Por exemplo: 'Impacto do diabetes na vida adulta e suas limitações'"
        @input="valueChange"/>
    </div>

    <div class="grid grid-cols-1 mt-4">
      <div class="w-full flex gap-2 justify-end">

        <vs-button
          :disabled="!validateForm"
          @click="generate">
          Gerar
        </vs-button>

        <vs-button
          type="border"
          @click="cancel">
          Cancelar
        </vs-button>
      </div>
    </div>

    <div class="grid grid-cols-1 mt-4" v-for="question in questions" :key="question">

      <vs-checkbox
        v-model="question.selected"
        style="margin-left: 25px;"
        class="mt-2"
      >
      <span>{{$t('base_question.card.action.select')}}</span>
      </vs-checkbox>

      <SimpleText
        :item="question"
        v-if="question.type === 'simple_text'"
        mode="form"
      ></SimpleText>
      <MultipleChoice
        :item="question"
        v-if="question.type === 'multiple_choice'"
        mode="form"
      ></MultipleChoice>
      <CheckBox
        :item="question"
        v-if="question.type === 'check_box'"
        mode="form"
      ></CheckBox>
      <Gap
        :item="question"
        v-if="question.type === 'gap'"
        mode="form"
      ></Gap>

    </div>

    <div class="grid grid-cols-1 mt-4" v-if="questions.length > 0">
      <div class="w-full flex gap-2 justify-end">

        <vs-button
          @click="addAllSelected()"
          class="flex-grow-0 flex-shrink-0"
          icon="add">
          {{$t('base_question.modal.add_selected')}}
        </vs-button>

      </div>
    </div>

  </div>

</template>

<script>
import OpenAIService from '@/services/api/OpenAIService'

const SimpleText = () => import('./questions/SimpleText.vue')
const MultipleChoice = () => import('./questions/MultipleChoice.vue')
const CheckBox = () => import('./questions/CheckBox.vue')
const Gap = () => import('./questions/Gap.vue')

export default {
  components: {
    SimpleText,
    MultipleChoice,
    CheckBox,
    Gap,
  },
  data: () => {
    return {
      openAIService: null,
      questionType: 'multiple_choice',
      prompt: '',
      questionsQty: 1,
      optionsQty: 4,
      temperature: 50,
      questions: []
    }
  },
  props: {

  },
  watch: {

  },
  computed: {
    validateForm() {
      return (
        !this.isEmpty(this.questionType) &&
        !this.isEmpty(this.prompt) &&
        !(this.questionType === 'multiple_choice' && this.isEmpty(this.optionsQty))
      )
    },
    hasOptions() {
      return this.questionType === 'multiple_choice' || this.questionType === 'check_box';
    }
  },
  methods: {
    addAllSelected() {
      const questionsSelected = this.questions.filter(question => {
        return 'selected' in question && question.selected
      })
      console.log('questionsSelected', questionsSelected)
      if (questionsSelected.length === 0) {
        this.notifyWarning(this.$vs, 'Nenhuma questão selecionada!', 3000)
      } else {
        this.$emit('pressedAddQuestions', questionsSelected)
      }
    },
    selectedOption(option) {

    },
    cancel() {

    },
    generate() {
      this.$vs.loading()

      const params = {
        question_type: this.questionType,
        prompt: this.prompt,
        options_qty: this.optionsQty,
        questions_qty: this.questionsQty,
        temperature: this.temperature / 100,
        max_tokens: 2048,
        top_p: 1
      }
      this.openAIService.generateNewQuestion(params).then(data => {
        console.log('generateNewQuestion...', data)
        this.questions.push(...data)
        this.$vs.loading.close()
      }, error => {
        this.$vs.loading.close()
      })
    }
  },
  created() {
    this.openAIService = OpenAIService.build(this.$vs)
  },
}
</script>

<style lang="scss">

</style>
