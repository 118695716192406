<template>
    <Grid
      direction="asc"
      ref="emailsGrid"
      :noDataText="noDataText"
      :service="contentQuestionnaireService"
      route_name="questionnaireEmailGrid"
      :route_grid_path="`/${id}/emails/${type}/grid`"
      :hide_actions="true"
      showTotalText="Total">
      <template v-slot:gridThead>
      <div key="selected" width="5%" style="margin-top: 10px;">
        <vs-checkbox v-model="headerSelected"/>
      </div>

      <vs-th key="user_name" sort-key="email" width="40%">{{ $t('name') }}</vs-th>
      <vs-th key="email" sort-key="email" width="40%">{{ $t('email') }}</vs-th>
      <vs-th key="status" sort-key="status" width="15%">{{ $t('status') }}</vs-th>
      </template>

      <template v-slot:gridData="props">
      <vs-tr :key="props.data[i]" v-for="(tr, i) in props.data" :class="formatCssRow(tr)">
        <vs-td key="selected">
          <vs-checkbox
            :vs-value="tr.id"
            v-model="selected"/>
        </vs-td>
        <vs-td key="user_name">{{ tr.user_name}}</vs-td>
        <vs-td key="email">{{tr.email}}</vs-td>
        <vs-td key="status">
        <div class="flex items-center">
          <vs-icon
            icon="check_circle"
            class="text-2xl pr-2"
            v-if="tr.status === 's'"/>
          <vs-icon
            icon="timer"
            class="text-2xl pr-2"
            v-if="tr.status === 'o'"/>
          <vs-icon
            @click="remove(tr)"
            icon="delete"
            class="text-2xl pr-2 icon-cursor"
            v-if="tr.status === 'p'"/>
          <vs-icon
            @click="remove(tr)"
            icon="delete"
            class="text-2xl pr-2 icon-cursor"
            v-if="tr.status === 'e'"/>
          <span>{{tr.status_fmt}}</span>
        </div>
        </vs-td>
      </vs-tr>
      </template>
    </Grid>
</template>

<script>
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import { valuesIn } from 'lodash'
export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: 'email'
    },
    noDataText: {
      type: String,
      default: function () {
        return this.$t('nenhum-registro-foi-encontrado')
      }
    }
  },

  data: () => ({
    contentQuestionnaireService: null,
    headerSelected: false,
    selected: []
  }),

  created() {
    this.contentQuestionnaireService = ContentQuestionnaireService.build(this.$vs)
  },

  watch: {
    headerSelected(val) {
      if (val === true) {
        this.contentQuestionnaireService.selectAllEmails(this.id, this.type).then(
          (data) => {
            this.selected = data
          },
          (error) => {
            this.notifyError(this.$vs, this.$t('nao-foi-possivel-selecionar-todos-os-ema'))
          }
        )
      } else {
        this.selected = []
      }
    },
    selected(val) {
      this.$emit('selected', {all: this.headerSelected, selection: this.selected})
    }
  },

  methods: {
    remove(whom) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro'),
        accept: () => {this.$emit('remove', whom)}
      })
    },
    reload() {
      this.$refs['emailsGrid'].fetchGridData()
    },
    selectMore(items) {
      console.log('selectMore...', items)
      const filteredItems = items.filter(element => {
        console.log('element', element)
        return element !== null
      })
      console.log('filteredItems', filteredItems)
      this.selected = this.selected.concat(filteredItems)
    },
    clearSelection() {
      this.selected = []
    },
    formatCssRow(item){
      const status = this._.get(item, 'status')
      let textCss = ''

      if (status === 'o'){
        textCss = 'text-success'
      }else if(status === 'p'){
        textCss = 'text-warning'
      }else if(status === 'e'){
        textCss = 'text-danger'
      }

      return textCss
    },
  }
}
</script>

<style scoped>
  .icon-cursor {
    cursor: pointer;
  }
</style>
