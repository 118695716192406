
<template>
  <div class="editor-panel">

    <vx-tour
      name="questionnaireEditorIntroSteps"
      :auto="true"
    />

    <div class="flex mb-4 items-center justify-center">
      <div class="grid grid-cols-12 w-full">
        <div class="col-span-3 relative lg:col-span-2" style="z-index: 4;" v-if="questionsLoaded">
          <div class="sticky topx">
            <div :class="cardClass" style="z-index: 1" id="editor-panel" ref="editorPanel">
              <vs-card>
                <div slot="header" style="text-align: center">
                  <span class="text-xs sm:text-sm md:text-base lg:text-base xl:text-base">
                    {{expandedItems ? 'PAINEL DE ITENS' : 'PAINEL'}}
                  </span>
                </div>
                <div>
                  <vs-button
                    v-show="showExpandButton()"
                    @click="expandItems()"
                    class="min-w-full pb-2 panel-item-btn"
                    style="justify-content: center"
                    color="primary"
                    icon="menu">
                  </vs-button>

                  <div v-show="expandedItems">
                    <drag class="pb-2" v-for="item in dragItems" :key="item.id" :data="item">
                      <vs-button
                        v-if="showButtonText"
                        class="min-w-full panel-item-btn cursor-move"
                        style="justify-content: flex-start"
                        color="primary"
                        type="border"
                        :icon="item.icon"
                        >
                        <span class="text-xs">{{ item.name }}</span>
                      </vs-button>

                      <vs-button
                        v-else
                        class="min-w-full panel-item-btn  cursor-move"
                        color="primary"
                        type="border"
                        :icon="item.icon"
                        ></vs-button>

                    </drag>
                    <vs-button
                        v-permission="'questionnaires.question.import'"
                        @click="showQuestionImportPopup = true"
                        class="min-w-full panel-item-btn"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-file-plus"
                        v-tooltip.top-end="{content: $t('action.import'),delay: { show: 500, hide: 100 },}">
                        <span class="text-xs" v-if="showButtonText">{{$t('action.import')}}</span>
                    </vs-button>
                  </div>
                </div>
                <div v-if="expandedItems" class="flex items-center justify-end pt-1">
                  <div class="absolute inset-preview-button">
                    <vs-button color="primary" radius
                      icon="remove_red_eye"
                      @click="preview()"
                      v-tooltip.top-end="{
                        content: 'Pré-visualizar',
                        delay: { show: 500, hide: 100 },
                      }"></vs-button>
                    </div>
                </div>
                <div v-else slot="footer" style="text-align: center">
                  <vs-row vs-justify="flex-end">
                    <vs-button color="primary" radius
                      icon="remove_red_eye"
                      @click="preview()"
                      v-tooltip.top-end="{
                        content: 'Pré-visualizar',
                        delay: { show: 500, hide: 100 },
                      }"></vs-button>
                  </vs-row>
                </div>
              </vs-card>
            </div>
          </div>
        </div>
        <div class="col-span-9 lg:col-span-10">
          <div class="text-center mt-4" v-if="questions.length === 0 && questionsLoaded" id="tour-editor-drop">
            {{ $t('insira-as-questoes-aqui-abaixo') }}
          </div>

          <div class="text-center mt-4" v-if="!questionsLoaded" id="tour-editor-drop">
            {{ $t('carregando-questoes-aguarde') }}
          </div>

          <drop-list
            v-show="questionsLoaded"
            :items="questions"
            @reorder="$event.apply(questions)"
            @insert="insert"
          >
            <template v-slot:item="{ item, reorder }">
              <drag
                :key="item.id"
                :data="item"
                @cut="remove(questions, item)"
                handle=".handle"
                @dragstart="dragStart(item)"
                @dragend="dragEnd(item)"
              >
                <vs-list :class="{'cursor-pointer': itemIsInvalidated(item)}" @click.native="handleItemClick(item)">
                  <div class="vx-row">
                    <div class="vx-col w-full">

                      <vs-list-item
                        @item-DELETE_ITEM="deleteItem($event)"
                        @item-DUPLICATE_ITEM="duplicateItem($event)"
                        @item-ITEM_UPDATED="updateItem($event)"
                        @item-INVALIDATE_ITEM="invalidateItem($event)"
                        @item-REVALIDATE_ITEM="revalidateItem($event)"
                        :class="getItemClass(item)"
                        :id="`${item.id}-item`"
                        :style="
                          reorder
                            ? { borderLeft: '2px solid #1976D2', marginLeft: '-2px' }
                            : {}
                        "
                      >
                        <div
                          class="handle"
                          @mouseenter="handleEnter(item)"
                          @mouseleave="handleLeave(item)"
                        >
                          <v-list-item-avatar>
                            <div v-show="item.handle && !dragging">
                              <font-awesome-icon icon="grip-horizontal" />
                            </div>
                          </v-list-item-avatar>
                        </div>
                        <SimpleText
                          :item="item"
                          v-if="item.type === 'simple_text'"
                          :parent_id="id"
                          :disabled="getItemDisability(item)"
                          :showInvalidateButton="showInvalidateQuestionButton"
                          :cheetEnabled="cheetEnabled"
                          @questionRendered="asyncLoadNextOption"
                        ></SimpleText>
                        <MultipleChoice
                          :item="item"
                          v-if="item.type === 'multiple_choice'"
                          :parent_id="id"
                          :disabled="getItemDisability(item)"
                          :showInvalidateButton="showInvalidateQuestionButton"
                          :cheetEnabled="cheetEnabled"
                          @questionRendered="asyncLoadNextOption"
                        ></MultipleChoice>
                        <CheckBox
                          :item="item"
                          v-if="item.type === 'check_box'"
                          :parent_id="id"
                          :disabled="getItemDisability(item)"
                          :showInvalidateButton="showInvalidateQuestionButton"
                          :cheetEnabled="cheetEnabled"
                          @questionRendered="asyncLoadNextOption"
                        ></CheckBox>
                        <Gap
                          :item="item"
                          v-if="item.type === 'gap'"
                          :parent_id="id"
                          :disabled="getItemDisability(item)"
                          :showInvalidateButton="showInvalidateQuestionButton"
                          :cheetEnabled="cheetEnabled"
                          @questionRendered="asyncLoadNextOption"
                        ></Gap>
                        <!--
                        <Media
                          :item="item"
                          v-if="item.type === 'media'"
                          :parent_id="id"
                          @questionRendered="asyncLoadNextOption"
                        ></Media>
                        <Essay
                          :item="item"
                          v-if="item.type === 'essay'"
                          :parent_id="id"
                          @questionRendered="asyncLoadNextOption"
                        ></Essay>
                        -->
                        <Section
                          :item="item"
                          v-if="item.type === 'section'"
                          :parent_id="id"
                          @questionRendered="asyncLoadNextOption"
                        ></Section>
                      </vs-list-item>

                    </div>

                  </div>

                  <div class="vx-row">

                    <div class="vx-col w-full">
                      <vs-dropdown class="flex float-right">
                        <vs-button icon="add">
                        </vs-button>
                        <vs-dropdown-menu class="newQuestionDropdown" style="width: 200px;">
                          <vs-dropdown-item>
                            <vs-button
                              class="p-2 w-full text-left"
                              @click="handleNewQuestion('section', item)"
                              type="flat" color="primary" icon="view_stream"
                            >
                              {{$t('question_type.section')}}
                            </vs-button>
                          </vs-dropdown-item>
                          <vs-dropdown-item>
                            <vs-button
                              class="p-2 w-full text-left"
                              @click="handleNewQuestion('simple_text', item)"
                              type="flat" color="primary" icon="edit"
                            >
                              {{$t('question_type.simple_text')}}
                            </vs-button>
                          </vs-dropdown-item>
                          <vs-dropdown-item>
                            <vs-button
                              class="p-2 w-full"
                              @click="handleNewQuestion('multiple_choice', item)"
                              type="flat" color="primary" icon="list"
                            >
                              {{$t('question_type.multiple_choice')}}
                            </vs-button>
                          </vs-dropdown-item>
                          <vs-dropdown-item>
                            <vs-button
                              class="p-2 w-full"
                              @click="handleNewQuestion('check_box', item)"
                              type="flat" color="primary" icon="check_box"
                            >
                              {{$t('question_type.check_box')}}
                            </vs-button>
                          </vs-dropdown-item>

                          <vs-dropdown-item>
                            <vs-button
                              class="p-2 w-full"
                              @click="handleNewQuestion('gap', item)"
                              type="flat" color="primary" icon="space_bar"
                            >
                              {{$t('question_type.gap')}}
                            </vs-button>
                          </vs-dropdown-item>
                          <!--
                          <vs-dropdown-item>
                            <vs-button
                              class="p-2 w-full"
                              @click="handleNewQuestion('media', item)"
                              type="flat" color="primary" icon="play_lesson"
                            >
                              {{$t('question_type.media')}}
                            </vs-button>
                          </vs-dropdown-item>

                          <vs-dropdown-item>
                            <vs-button
                              class="p-2 w-full"
                              @click="handleNewQuestion('essay', item)"
                              type="flat" color="primary" icon="article"
                            >
                              {{$t('question_type.essay')}}
                            </vs-button>
                          </vs-dropdown-item>
                          -->
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </div>

                  </div>
                </vs-list>

              </drag>
            </template>

            <template v-slot:feedback="{ data }">
              <div class="item feedback" :key="data">
                <vue-content-loading
                  :width="300"
                  :height="7"
                  v-if="data.type === 'section'"
                >
                  <rect x="0" y="2" rx="4" ry="4" width="300" height="5" />
                </vue-content-loading>
                <vue-content-loading :width="300" :height="32" v-else>
                  <rect x="3" y="5" rx="4" ry="4" width="100" height="7" />
                  <rect x="3" y="15" rx="4" ry="4" width="50" height="7" />
                  <rect x="3" y="25" rx="4" ry="4" width="75" height="7" />
                </vue-content-loading>
              </div>
            </template>
            <div style="height: 200px;"></div>
          </drop-list>
        </div>
      </div>
    </div>

    <div class="status-bar grid grid-cols-1 fixed bottom-0 ..." :style="statusBarStyle">
      <vs-card>
        <div class="flex justify-between gap-2">
          <div>
            <div class="grid grid-cols-1 ml-4">
              <div class="font-bold flex items-center justify-center primary-color">{{ $t('questoes') }}</div>
              <div class="flex items-center justify-center font-bold">
                {{questionCount}}
              </div>
            </div>
          </div>

          <div>
            <div class="grid grid-cols-1">
              <div class="font-bold flex items-center justify-center primary-color">{{ $t('points') }}</div>
              <div class="flex items-center justify-center font-bold">
                {{totalScore}} / {{content.average_score ? content.average_score : '-'}}
              </div>
            </div>
          </div>

          <div>
            <div class="grid grid-cols-1">
              <div class="font-bold flex items-center justify-center primary-color">{{ $t('media') }}</div>
              <div class="flex items-center justify-center font-bold">
                {{calcAverageScore()}}
              </div>
            </div>
          </div>

          <div v-if="hasQuestionDuration">
            <div class="grid grid-cols-1">
              <div
                class="font-bold flex items-center justify-center primary-color"
                v-tooltip.auto="{
                  content: 'Soma de Durações por Questão',
                  delay: {
                    show: 500, hide: 500
                  }
                }"
              >
                SDQ
              </div>
              <div class="flex items-center justify-center font-bold">
                {{totalQuestionsDuration}}
              </div>
            </div>
          </div>

          <div v-if="randomType">
            <div class="grid grid-cols-1">
              <div
                class="font-bold flex items-center justify-center primary-color"
                v-tooltip.auto="{
                  content: qqaTooltipContent,
                  delay: {
                    show: 500, hide: 500
                  }
                }"
              >
                QQA
              </div>
              <div class="flex items-center justify-center font-bold">
                {{randomCount}}
              </div>
            </div>
          </div>

          <div class="flex items-center justify-center">
            <div class="grid grid-cols-1 md:mr-6">
              <div class="flex items-center justify-center primary-color font-bold">
                {{ updatedAt.value }}
              </div>
            </div>
          </div>

        </div>
      </vs-card>
    </div>

    <vs-popup
      :title="$t('banco-de-questoes')"
      :active.sync="showBaseQuestionModal"
      :fullscreen="true"
      v-esc="closeBaseQuestionModal"
    >
      <BaseQuestionModal
        v-if="showBaseQuestionModal"
        @pressedAddQuestions="addSelectedBaseQuestions"
      >
      </BaseQuestionModal>
    </vs-popup>

    <vs-popup
      title="Integração OpenAI"
      :active.sync="showOpenAIModal"
      :fullscreen="true">
      <OpenAIModal
        v-if="showOpenAIModal"
        @pressedAddQuestions="addSelectedOpenAI"
        v-esc="closeOpenAIModal">
      </OpenAIModal>
    </vs-popup>

    <vs-popup
      :title="$t('importacao')"
      :active.sync="showQuestionImportPopup"
      style="overflow-y: auto; z-index: 54000;">
      <div class="grid grid-cols-1 gap-4 pt-4">
        <content-file-upload
          @input="successImportQuestion"
          :uploadUrl="sheetUploadImportQuestionUrl"
          :autoProcessUpload="false"
          :maxFiles="1"
          customMessageSuccess="iniciando-processamento-do-arquivo"
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
      </div>
    </vs-popup>

  </div>
</template>

<script>
import { Drag, DropList } from 'vue-easy-dnd'
import VueContentLoading from 'vue-content-loading'
import BaseQuestionModal from './basequestion/BaseQuestionModal'
import OpenAIModal from './OpenIAModal.vue'
import Constants from '@/util/Constants'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import BaseQuestionService from '@/services/api/BaseQuestionService'
import { isArray } from 'lodash'
import AnswerRandomQuestionGenerator from '@/util/AnswerRandomQuestionGenerator'
import cheet from 'cheet.js'

const VxTour = () => import('@/components/VxTour.vue')

const SimpleText = () => import('./questions/SimpleText.vue')
const MultipleChoice = () => import('./questions/MultipleChoice.vue')
const CheckBox = () => import('./questions/CheckBox.vue')
const Gap = () => import('./questions/Gap.vue')
const Media = () => import('./questions/Media.vue')
const Essay = () => import('./questions/Essay.vue')
const Section = () => import('./questions/Section.vue')
import ContentFileUpload from '@/components/ContentFileUpload.vue'

export default {
  name: 'EditorPanel',
  components: {
    Drag,
    DropList,
    VueContentLoading,
    SimpleText,
    Section,
    MultipleChoice,
    CheckBox,
    Gap,
    Media,
    Essay,
    BaseQuestionModal,
    VxTour,
    OpenAIModal,
    ContentFileUpload
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    content: {
      type: Object,
      default: null,
    },
    service: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      key: 0,
      contentQuestionnaireService: null,
      baseQuestionService: null,
      questionIndex: 0,
      dragging: false,
      statusBarStyle: '',
      dragItems: [
        {
          id: 1,
          type: 'base_question',
          name: this.$t('banco-questao'),
          handle: false,
          icon: 'unarchive',
          data: {},
        },
        {
          id: 2,
          type: 'openai',
          name: 'IA',
          handle: false,
          icon: 'smart_toy',
          data: {},
        },
        {
          id: 3,
          type: 'section',
          name: this.$t('sessao'),
          handle: false,
          icon: 'view_stream',
          data: {
            title: '',
            editor_type: null,
            question_duration: null
          },
        },
        {
          id: 4,
          type: 'simple_text',
          name: this.$t('texto'),
          handle: false,
          icon: 'edit',
          data: {
            title: '',
            correct_feedback: '',
            wrong_feedback: '',
            required: false,
            score: null,
            editor_type: null,
            input_size: 'short',
            question_duration: null,
            character_limit: null
          },
        },
        {
          id: 5,
          type: 'multiple_choice',
          name: this.$t('question_type.multiple_choice'),
          handle: false,
          icon: 'list',
          data: {
            title: '',
            correct_feedback: '',
            wrong_feedback: '',
            required: false,
            options: [],
            sort_options: false,
            score: null,
            editor_type: null,
            enumerate: null,
            question_duration: null
          },
        },
        {
          id: 6,
          type: 'check_box',
          name: this.$t('caixa-selecao'),
          handle: false,
          icon: 'check_box',
          data: {
            title: '',
            correct_feedback: '',
            wrong_feedback: '',
            required: false,
            options: [],
            sort_options: false,
            score: null,
            editor_type: null,
            enumerate: null,
            question_duration: null
          },
        },
        {
          id: 7,
          type: 'gap',
          name: this.$t('question_type.gap'),
          handle: false,
          icon: 'space_bar',
          data: {
            title: '',
            correct_feedback: '',
            wrong_feedback: '',
            required: false,
            options: [],
            sort_options: false,
            score: null,
            editor_type: null,
            enumerate: null,
            question_duration: null
          },
        },
        /*
        {
          id: 8,
          type: 'media',
          name: this.$t('question_type.media'),
          handle: false,
          icon: 'play_lesson',
          data: {
            title: '',
            correct_feedback: '',
            wrong_feedback: '',
            required: false,
            options: [],
            sort_options: false,
            score: null,
            editor_type: null,
            enumerate: null,
            question_duration: null
          },

        },
        {
          id: 9,
          type: 'essay',
          name: this.$t('question_type.essay'),
          handle: false,
          icon: 'article',
          data: {
            title: '',
            correct_feedback: '',
            wrong_feedback: '',
            required: false,
            options: [],
            sort_options: false,
            score: null,
            editor_type: null,
            enumerate: null,
            question_duration: null
          },

        },
        */
      ],
      questions: [],
      tempQuestions: [],
      questionsLoaded: false,
      showBaseQuestionModal: false,
      showOpenAIModal: false,
      dropQuestionIndex: null,
      isSmallQuestionScreen: false,
      showButtonText: false,
      mainRowClass: '',
      cardClass: '',
      expandedItems: false,
      editorPanelStartTopOffset: 0,
      updatedAt: {
        value: null,
        timer: null
      },
      scrollTimeout: null,
      timeout: null,
      queueIntervals: [],
      pausedQueue: false,
      cheetEnabled: false,
      showQuestionImportPopup: false
    }
  },
  methods: {
    asyncLoadNextOption() {
      if (!this.pausedQueue) {
        this.queueIntervals.push(
          setTimeout(() => {
            if (this.tempQuestions && this.tempQuestions.length > 0) {
              this.questions.push(...this.tempQuestions.splice(0, 1))
            }

            if (this.tempQuestions.length === 0 && this.questions.length > 0) {
              this.questionsLoaded = true
            } else {
              this.questionsLoaded = false
            }

          }, 10)
        )
      }
    },
    overviewMenuCol(width) {
      return `col-span-${width} grid grid-cols-1 sm:grid-cols-2 gap-x-2 overviewMenuCol`
    },
    handleItemClick(item) {
      const invalidated = this._.get(item, 'data.invalidated.justification', false)
      if (invalidated !== false) {
        this.$root.$emit('footer-open-invalidation-prompt', item)
      }
    },
    deleteItem(item) {
      //Only if published
      if(this.content && this.content.published_at != null){
        this.$utils.browser.confirmationDialog(this.$vs,
          this.$t('tem-certeza-que-deseja-excluir-esta-questao'),
          () => {
            this.questions = this.questions.filter((element) => {
              return element.id !== item.id
            })
            this.mountJsonData()
          }
        )
      } else {
        this.questions = this.questions.filter((element) => {
            return element.id !== item.id
          })
          this.mountJsonData()
      }
    },
    getItemDisability(item) {
      const invalidated = this._.get(item, 'data.invalidated', null)
      if (invalidated) {
        return true
      }
      return false
    },
    getItemClass(item) {
      const base = 'list-item'
      const invalidated = this._.get(item, 'data.invalidated', null)

      if (invalidated) {
        return `${base} invalidated-item`
      }

      return base
    },
    invalidateItem(event) {
      const position = this.questions.findIndex((element) => {
        return element.id === event.item.id
      })

      if (position !== -1) {
        // if (event.type === 'zero') {
        //   this.questions[position].data.score = 0
        // }
        this.questions[position].data.invalidated = {
          justification: event.justification,
          type: event.type
        }

        const isUpdate = this._.get(event, 'isUpdating')

        const message = isUpdate ?
          this.$t('libere-as-notas-novamente-ou-acione-recalcular-dados-no-resumo-do-questionario-para-que-atualize-as-notas') :
          this.$t('questao-anulada-para-aplicar-a-anulacao-nas-respostas-libere-as-notas-novamente-ou-acione-recalcular-dados-no-resumo-do-questionario')

        const color = isUpdate ? 'warning' : 'success'
        const title = isUpdate ? this.$t('a-anulacao-foi-alterada') : `${this.$t('sucesso')}!`

        this.$vs.dialog({
          color: color,
          title: title,
          text: message,
          acceptText: this.$t('ok'),
          accept: () => {
            /* Nothing to do here... */
          }
        })

        this.mountJsonData()
        this.notifySuccess(this.$vs, this.$t('questao-anulada-com-sucesso'))
      }
    },
    revalidateItem(event) {
      const position = this.questions.findIndex((element) => {
        return element.id === event.item.id
      })

      if (position !== -1) {
        this.questions[position].data.invalidated = undefined
        this.$vs.dialog({
          color: 'success',
          title: `${this.$t('sucesso')}!`,
          text: this.$t('anulacao-revertida-com-sucesso-se-ja-tiver'),
          acceptText: this.$t('ok'),
          accept: () => {
            /* Nothing to do here... */
          }
        })
        this.mountJsonData()
      }
    },
    itemIsInvalidated(item) {
      const invalidated = this._.get(item, 'data.invalidated.justification', false)
      if (invalidated !== false) {
        return true
      }

      return false
    },
    duplicateItem(sourceQuestion) {
      const index = this.questions
        .map((element) => {
          return element.id
        })
        .indexOf(sourceQuestion.id)

      const question = this._.cloneDeep(sourceQuestion)
      if ('base_question_id' in question) {
        delete question.base_question_id
      }
      if ('theme_id' in question) {
        delete question.theme_id
      }
      this.insertQuestion(question, index + 1, true)
      this.mountJsonData()
    },
    updateItem(item) {
      const question = this.questions.find((element) => {
        return element.id === item.id
      })

      if (question) {
        question.data = item.data
      }

      this.mountJsonData()
    },
    expandItems() {
      this.expandedItems = !this.expandedItems
      this.checkCardClass()
    },
    showExpandButton() {
      return this.windowWidth <= Constants.WINDOW_WIDTH_SM
    },
    insert(event) {
      const question = this._.cloneDeep(event.data)
      question.data.title = '' // Force clear title

      if (question.type === 'base_question') {
        this.dropQuestionIndex = event.index
        this.showBaseQuestionModal = true
      } else if (question.type === 'openai') {
        this.dropQuestionIndex = event.index
        this.showOpenAIModal = true
      } else {
        this.insertQuestion(question, event.index, true)
      }
    },
    dragStart(item) {
      this.dragging = true
    },
    dragEnd(item) {
      this.dragging = false
    },
    handleEnter(item) {
      item.handle = true
    },
    handleLeave(item) {
      item.handle = false
    },
    insertQuestion(question, index, newQuestion) {
      if (newQuestion) {
        question.id = this.$uuidKey()
      }

      if (!('score' in question.data)) {
        question.data.score = ''
      }

      //Default
      if(question.type != 'section' && !question.data.score){
        question.data.score = this.getDefaultQuestionScore()
      }


      setTimeout(() => {
        const offset   = this.getElementHeight(`${question.id}-item`)
        this.smoothScroll(offset - 100, 300)
      }, 500)
      this.questions.splice(index, 0, question)
      this.mountJsonData()
    },
    addSelectedBaseQuestions() {
      const selectedQuestions = this.$store.getters['basequestion/selectedQuestions']
      selectedQuestions.forEach((selected) => {
        const selectedData = JSON.parse(selected.data)
        if ('owner_id' in selected && selected.owner_id === null) {
          selectedData.id = this.$uuidKey()
        }
        if ('difficulty_level' in selected && selected.difficulty_level) {
          selectedData.data.difficulty = selected.difficulty_level
        }
        if ('themes' in selected && selected.themes && selected.themes.length > 0) {
          selectedData.theme_id = selected.themes[0].id
        }
        selectedData.base_question_id = selected.id
        const questionAlreadyInserted = this.questions.find((question) => {
          return question.id === selectedData.id
        })
        if (questionAlreadyInserted) {
          this.notifyWarning(this.$vs, this.$t('uma-ou-mais-questoes-secionada'))
        } else {
          this.insertQuestion(
            selectedData,
            this.dropQuestionIndex,
            false
          )
          this.dropQuestionIndex += 1
        }
      })
      this.showBaseQuestionModal = false
      this.$store.dispatch('basequestion/wipeSelections')
    },
    addSelectedOpenAI(selectedQuestions) {
      selectedQuestions.forEach(question => {
        this.insertQuestion(
          question,
          this.dropQuestionIndex,
          false
        )
        this.dropQuestionIndex += 1
      })
      this.showOpenAIModal = false
    },
    questionsSanetized() {
      const questions = this._.cloneDeep(this.questions)
      questions.forEach(question => {
        if (!this.isEmpty(question.data) && !this.isEmpty(question.data.options) && question.data.options.length > 0) {
          question.data.options.forEach(option => {
            if (!this.isEmpty(option.image)) {
              if ('key' in option) {
                delete option.key
              }
            }
          })
        }
      })
      return questions
    },
    mountJsonData() {
      if (this.id !== null) {

        this.updateContentData()

        // Debounce questionnaire save
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          const form = {
            id: this.id,
            data: {
              fields: this.questionsSanetized(),
            },
          }

          this.showToolbarLoading()
          this.contentQuestionnaireService
            .storeQuestion(form)
            .then((response) => {
              this.content.updated_at = response.updated_at
              this.activeLoading = false
              this.setUpdatedAt()
            }, (error) => {
              this.notifyError(this.$vs, this.$t('houve-um-erro-ao-atualizar-este-questionario'))
            })
        }, 3000)
      }
    },
    updateContentData() {
      const data = this.isEmpty(this.content.data) ? {} : JSON.parse(this.content.data)
      data.fields = this.questions
      this.content.data = JSON.stringify(data)
      this.$root.$emit('QUESTIONNAIRE_CONTENT_UPDATED', this.content)
    },
    preview() {
      const routeData = this.$router.resolve({
        name: 'questionnaires_form_answers_preview',
        params: { short_url_id: this.content.short_url_id},
      })
      window.open(routeData.href, '_blank')
    },
    setUpdatedAt() {
      const updatedAt = this.readableHumanTime(
        this.content.updated_at, true
      )
      const result = this.activeLoading
        ? 'Atualizando...'
        : !this.isEmpty(updatedAt)
          ? this.$t('atualizado-updatedat', [updatedAt])
          : '...'

      this.updatedAt.value = result
      return result
    },
    showToolbarLoading() {
      this.updatedAt.value = 'Atualizando...'
    },
    resizeEventHandler(width) {
      this.checkQuestionScreen(width)
    },
    checkQuestionScreen(width) {
      this.isSmallQuestionScreen = width < Constants.WINDOW_WIDTH_MD
      this.showButtonText = width >= Constants.WINDOW_WIDTH_MD
      this.mainRowClass = width < Constants.WINDOW_WIDTH_MD ? 'vx-row h-12' : 'vx-row h-12 w-full'
      this.expandedItems = width > Constants.WINDOW_WIDTH_SM
      this.checkCardClass()
    },
    checkCardClass() {
      const cardClass = 'items-card-fixed items-card-min-content'
      this.cardClass = this.expandedItems ? `${cardClass} items-card-max-width-expanded` : `${cardClass} items-card-max-width-collapsed`
    },
    calcAverageScore() {
      return this.totalScore > 0 ? (this.totalScore / this.questionCount).toFixed(2) : '0.0'
    },
    calcStatusBarWidth() {
      const navWidth = this.verticalNavMenuWidth === 'default' ?  260 : -14
      this.statusBarStyle = `width: calc(100% - ${navWidth}px)`
    },
    offset(el) {
      const rect = el.getBoundingClientRect()
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    },
    handleScroll(event) {
      // const editorPanel = this.$refs['editorPanel']
      // if (!this.isEmpty(editorPanel)) {
      //   const parentPanel = editorPanel.parentNode
      //   const parentPanelOffset = this.offset(parentPanel)
      //   this.editorPanelStartTopOffset = parentPanelOffset.top
      //   if (this.scrollTimeout) clearTimeout(this.scrollTimeout)
      //   this.scrollTimeout = setTimeout(() => {
      //     this.topAnimationMove(editorPanel, window.scrollY + 100, 2, this.editorPanelStartTopOffset)
      //   }, 500)
      // }
    },
    calcEditorPanelTop() {
      const editorPanel = this.$refs['editorPanel']
      if (editorPanel) {
        const offsets = editorPanel.getBoundingClientRect()
        this.editorPanelStartTopOffset = offsets.top
      }
    },
    enableSmartScroll() {
      window.addEventListener('scroll', this.handleScroll)
    },
    disableSmartScroll() {
      if (this.$refs.editorPanel && this.$refs.editorPanel.style) {
        this.$refs.editorPanel.style.top = 'unset'
      }
      window.removeEventListener('scroll', this.handleScroll)
    },
    clearIntervals() {
      if (this.updatedAt && this.updatedAt.timer) {
        clearInterval(this.updatedAt.timer)
      }

      if (this.timeout) {
        clearInterval(this.timeout)
      }

      if (isArray(this.queueIntervals) && this.queueIntervals.length > 0) {
        this.queueIntervals.forEach((interval, pos) => {
          clearInterval(this.queueIntervals[pos])
        })
      }
    },
    storeQuestionFromFooter(question) {
      const questionIndex = this.questions.findIndex((_question) => _question.id === question.id)
      if (question) {
        const newBaseQuestionScaffold = {
          id: 'base_question_id' in question ? question.base_question_id : null,
          data: JSON.stringify(question),
          title: question.data.title,
          uuid: question.id,
          owner_id: this.$store.state.AppActiveUser.id,
          discipline_id: this.content.discipline_id
        }
        this.$vs.loading()
        this.baseQuestionService.createOrUpdate(newBaseQuestionScaffold).then(
          (model) => {
            // Update the 'base_question_id' in the questionnaire question data newly stored question.
            if (questionIndex !== -1) {
              this.questions[questionIndex].base_question_id = model.id
              this.mountJsonData()
            }

            const newLabel = 'new' in model ? ' nova' : ''
            this.$vs.loading.close()
            this.notifySuccess(this.$vs,
              this.$t('base_question.store_success_personal', [newLabel, model.id]),
              6000
            )
          },
          (error) => {
            this.$vs.loading.close()
            this.notifyError(this.$vs, this.$t('base_question.store_error'))
            const errorLabel = 'statusText' in error ?
              `${error.status} ${error.statusText}` : undefined
            console.error(errorLabel || error)
          }
        )
      }
    },
    questionScoreChanged(questionId) {
      console.log(`I changed the score on ${questionId}`)
      if (this.id) {
        this.service.getStatusIndicators(this.id).then(
          indicators => {
            const finishedAnswers = this._.get(indicators, 'finished_answers', 0)
            if (finishedAnswers > 0) {
              this.notifyWarning(this.$vs, this.$t('atualize-a-correcao-em-respostas'), 8000)
            }
          }
        )
      } else {
        /**
         * Easter Egg
         * Shouldnt happen, but if it does, our client or our master tester guy will tell us.
         */
        this.notifyWarning(this.$vs, this.$t('algo-esta-errado-por-favor-entre-em-contato'), 6000)
      }
    },
    getDefaultQuestionScore() {
      return this.defaultQuestionScore
    },
    closeOpenAIModal() {
      this.showOpenAIModal = false
    },
    closeBaseQuestionModal() {
      this.showBaseQuestionModal = false
    },
    handleNewQuestion(type, question) {
      let questionData = null
      switch (type) {
      case 'section':
        questionData = this.dragItems[2]
        break
      case 'simple_text':
        questionData = this.dragItems[3]
        break
      case 'multiple_choice':
        questionData = this.dragItems[4]
        break
      case 'check_box':
        questionData = this.dragItems[5]
        break
      case 'gap':
        questionData = this.dragItems[6]
        break

      default:
        break
      }

      const position = this.questions.findIndex((element) => {
        return element.id === question.id
      })

      const event = {
        data: questionData,
        index: position + 1
      }
      this.insert(event)
    },
    successImportQuestion(data){
      let fields = data.questions
      this.questions = fields
      this.showQuestionImportPopup = false
    }
  },
  computed: {

    // Time per Question info
    hasQuestionDuration() {
      return (
        this.individualDurations.filter((duration) => duration !== 0).length !== 0 ||
        this.defaultQuestionDuration !== 0
      )
    },
    defaultQuestionDuration() {
      return parseFloat(this._.get(this.content, 'question_duration') || 0)
    },
    defaultQuestionScore() {
      return parseFloat(this._.get(this.content, 'question_score') || '')
    },
    individualDurations() {
      const durations = []
      this.questions.forEach((question) => {
        if (_.get(question, 'type') === 'section') return
        const duration = parseFloat(this._.get(question, 'data.question_duration') || 0)
        durations.push(duration)
      })

      return durations
    },
    totalQuestionsDuration() {
      let durationSum = 0.0
      this.individualDurations.forEach((duration) => {
        durationSum += duration || this.defaultQuestionDuration
      })

      return this.$moment.duration(durationSum, 'minutes').humanize()
    },
    // Random Questions info
    randomType() {
      return this._.get(this.content, 'random_questions.type') || false
    },
    randomCount() {
      return this._.get(this.content, 'random_questions.parameters.questions') || false
    },
    qqaTooltipContent() {
      let content = this.$t('quantidade-de-questoes-aleatorias-usuario')
      const ratios = this.totalRandomQuestionsPerUser
      if (this._.isArray(ratios) && ratios.length > 0) {
        ratios.forEach((groupRatio) => {
          if (this.randomType === 'difficulty') {
            const groupKeyTranslated = this.$t(`random_questions.group_key.${groupRatio.name}`)
            const count = groupRatio.value
            content += this.$t('count-x-questoes-groupkeytranslated-less', [count, groupKeyTranslated])
          } else if (this.randomType === 'score') {
            const groupKeyTranslated = `${this.formatFloat(groupRatio.name)} pontos`
            const count = groupRatio.value
            content += this.$t('count-x-questoes-groupkeytranslated-less', [count, groupKeyTranslated])
          }

        })
      }

      return content
    },
    totalRandomQuestionsPerUser() {
      if (this.content) {
        const content = this._.cloneDeep(this.content)
        const generator = new AnswerRandomQuestionGenerator(content)
        const ratios = generator.groupRatios()

        return Array.from(ratios, ([name, value]) => ({ name, value }))
      }
      return []
    },

    questionCount() {
      let count = 0
      this.questions.forEach(question => {
        if (question.type !== 'section') {
          count++
        }
      })
      return count ? count : '-'
    },
    serverOnline() {
      return this.$store.getters.serverOnline
    },
    internetOnline() {
      return this.$store.getters.internetConnection
    },
    totalScore() {
      let score = 0
      if (this.questions.length > 0) {
        this.questions.forEach(question => {
          if (question.type !== 'section') {
            score += question.data.score ? parseFloat(question.data.score) : 0.0
          }
        })
      }
      return score ? score : '-'
    },
    questionList() {
      return this.questions
    },
    showDragEmptyInfo() {
      return this.questions.length === 0
    },
    panelItemsClass() {
      return this.showButtonText ? 'vx-col w-2/12' : 'vx-col w-3/12'
    },
    panelQuestionsClass() {
      return this.showButtonText ? 'vx-col w-10/12 h-12' : 'vx-col w-9/12 h-12'
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    questionnaireFinishAt() {
      return this._.get(this.content, 'finish_at', null)
    },
    questionnaireScoreReleased() {
      return this._.get(this.content, 'score_released_at', null)
    },
    questionnaireAnswersAmount() {
      const answerCount = this._.get(this.content, 'answer_count', 0)
      return answerCount
    },
    showInvalidateQuestionButton() {
      const now = this.$moment()
      const finishAt = this.questionnaireFinishAt
      const scoreReleasedAt = this.questionnaireScoreReleased

      let afterFinish = false
      let afterScoreRelease = false

      if (finishAt) {
        afterFinish = now.isAfter(finishAt)
      }

      if (scoreReleasedAt) {
        afterScoreRelease = now.isAfter(scoreReleasedAt)
      }

      return afterFinish || afterScoreRelease || this.questionnaireAnswersAmount > 0
    },
    sheetUploadImportQuestionUrl(){
      return `${process.env.VUE_APP_API_BASE_URL}/api/v1/content_questionnaires/${this.id}/questions/import/sheets`
    }
  },
  mounted() {
    console.log('question_score_default---'+this.defaultQuestionScore)
    // this.calcEditorPanelTop()
    this.$root.$on('store-question', this.storeQuestionFromFooter)
    this.$root.$on('footer-SCORE_CHANGED', this.questionScoreChanged)
    this.checkQuestionScreen(this.windowWidth)
    this.$vs.loading.close()
    this.setUpdatedAt()
    this.updatedAt.timer = setInterval(() => {
      this.setUpdatedAt()
    }, 60000)

    cheet('k k k', () => {
      this.cheetEnabled = !this.cheetEnabled
      console.info(`kkk cheat ${this.cheetEnabled ? 'enabled' : 'disabled'}!!!`)
    })
  },
  beforeDestroy() {
    this.pausedQueue = true
    this.$root.$off('store-question', this.storeQuestionFromFooter)
    this.$root.$off('footer-SCORE_CHANGED', this.questionScoreChanged)
    this.clearIntervals()
  },
  watch: {
    windowWidth() {
      this.resizeEventHandler(this.windowWidth)
    },
    verticalNavMenuWidth() {
      this.calcStatusBarWidth()
    },
    // O sistema pode estar passando por uma instabilidade momentânea ou você pode não estar conectado à internet.
    serverOnline(val) {
      if (!val) {
        this.notifyWarning(this.$vs, this.$t('o-sistema-pode-estar-passando-por-uma-instabilidade'), 5000)
      }
    },
    internetOnline(val) {
      if (!val) {
        this.notifyWarning(this.$vs, this.$t('internet-fora-do-ar-verifique-sua-conexao'), 8000)
      }
    }
  },
  created() {
    this.enableSmartScroll()
    this.calcStatusBarWidth()
  },
  beforeMount() {
    this.contentQuestionnaireService = ContentQuestionnaireService.build(
      this.$vs
    )
    this.baseQuestionService = BaseQuestionService.build(this.$vs)
    const data =
      this.content && this.content.data ? JSON.parse(this.content.data) : null
    const fields = data ? data.fields : []
    if (fields && fields.length > 0) {
      setTimeout(() => {
        this.tempQuestions = JSON.parse(this.content.data).fields.map(
          (question) => ({
            ...question,
            data: {
              ...question.data,
              question_duration: question.data.question_duration || null
            }
          })
        )
        this.asyncLoadNextOption()
      }, 1000)
    }
    this.questionsLoaded = true
  },
  activated() {
    console.log('EditorPanel activated...')
  }
}
</script>

<style lang="scss">

.topx {
  top: 7rem;
}

@media (min-width: 560px) {
  .overviewMenuCol > div {
    justify-self: start !important;
  }

  .overviewMenuCol > label {
    justify-self: end !important;
  }
}

@media (max-width: 560px) {
  .overviewMenuCol > div {
      justify-self: center !important;
  }

  .overviewMenuCol > label {
    justify-self: center !important;
  }
}

.status-bar {
  z-index: 3;
  margin-left: -51px;
  opacity: 0.7;
  background-color: $grey;
  .vs-card--content {
    margin-bottom: 0px !important;
  }
  .con-vs-card {
    border-radius: 0px;
    margin-bottom: 0px !important;
  }
}

.overviewBar {
  z-index: 9999999999999;
}

.vue-back-to-top {
  bottom: 10% !important;
}

.drop-allowed.drop-in * {
  cursor: inherit !important;
}

.handle {
  width: 100%;
  height: 20px;
  text-align: center;
  cursor: move;
}

.handle > div {
  text-align: center;
}

.vs-list--slot {
  margin: 0;
  width: 100%;
}

.fa-grip-horizontal {
  color: lightgray;
  font-size: 16px;
}

.list-item {
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #fff;
}

.items-card-fixed {
  position: absolute;
  z-index: 9999999999999;
}

@media (min-width: 768px) {
  .items-card-min-content {
    max-width: min-content !important;
  }
}

@media (max-width: 560px) {
  .items-card-max-width-collapsed {
    max-width: 55px !important;
  }
  .items-card-max-width-expanded {
    max-width: 70px !important;
  }
}

.items-card .vs-button-text {
  font-size: 12px;
}

.item {
  padding: 20px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.feedback {
  border: 2px dashed white;
}

.drag-image {
  transform: translate(-50%, -50%);
}

.inset-preview-button {
  bottom: -0.50rem;
  right: 0.5rem;
}

.panel-item-btn {
  padding-top: 0.3rem !important;
  padding-bottom: 0.4rem !important;
  i.vs-icon {
    font-size: 30px;
  }
}

.fill-row-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .loading-example {
    width: 120px;
    float: left;
    height: 120px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05);
      transform: translate(0, 4px);
    }
    h4 {
      z-index: 40000;
      position: relative;
      text-align: center;
      padding: 10px;
    }
    &.activeLoading {
      opacity: 0 !important;
      transform: scale(0.5);
    }
  }
}

.land {
  @media only screen and (orientation: portrait) and (max-width: 555px) {
    transform: rotate(90deg);
    width: calc(155%);
    .content {
      width: calc(155%);
    }
  }

  .drop-list > .panel-margin {
    margin: 0 5px;
  }
}

.add-new-class {
  margin-top: 10px;
  position: absolute;
  right: 0;
  margin-right: -10px;
}
</style>
