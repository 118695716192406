var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _c("label", { staticClass: "w-full text-left block" }, [
        _vm._v(" Tipo de questão: "),
      ]),
      _c(
        "div",
        { staticClass: "grid grid-cols-4" },
        [
          _c(
            "vs-radio",
            {
              staticClass: "mt-2",
              attrs: { "vs-name": "sourcefilter", "vs-value": "simple_text" },
              on: { input: _vm.selectedOption },
              model: {
                value: _vm.questionType,
                callback: function ($$v) {
                  _vm.questionType = $$v
                },
                expression: "questionType",
              },
            },
            [_vm._v(" Texto ")]
          ),
          _c(
            "vs-radio",
            {
              staticClass: "mt-2",
              attrs: {
                "vs-name": "sourcefilter",
                "vs-value": "multiple_choice",
              },
              on: { input: _vm.selectedOption },
              model: {
                value: _vm.questionType,
                callback: function ($$v) {
                  _vm.questionType = $$v
                },
                expression: "questionType",
              },
            },
            [_vm._v(" Múltipla Escolha ")]
          ),
          _c(
            "vs-radio",
            {
              staticClass: "mt-2",
              attrs: { "vs-name": "sourcefilter", "vs-value": "check_box" },
              on: { input: _vm.selectedOption },
              model: {
                value: _vm.questionType,
                callback: function ($$v) {
                  _vm.questionType = $$v
                },
                expression: "questionType",
              },
            },
            [_vm._v(" Caixa de Seleção ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "grid grid-cols-1 mt-4" }, [
        _c("div", { staticClass: "grid grid-cols-4" }, [
          _c(
            "div",
            [
              _c("label", { staticClass: "w-full text-left block" }, [
                _vm._v(" Quantidade questões: "),
              ]),
              _c("vs-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: ["###"],
                    expression: "['###']",
                  },
                ],
                attrs: { name: "questionsQty", type: "number" },
                model: {
                  value: _vm.questionsQty,
                  callback: function ($$v) {
                    _vm.questionsQty = $$v
                  },
                  expression: "questionsQty",
                },
              }),
            ],
            1
          ),
          _vm.hasOptions
            ? _c(
                "div",
                [
                  _c("label", { staticClass: "w-full text-left block" }, [
                    _vm._v(" Quantidade alternativas: "),
                  ]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: ["#"],
                        expression: "['#']",
                      },
                    ],
                    attrs: { name: "optionsQty", type: "number" },
                    model: {
                      value: _vm.optionsQty,
                      callback: function ($$v) {
                        _vm.optionsQty = $$v
                      },
                      expression: "optionsQty",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c("label", { staticClass: "w-full text-left block" }, [
                _vm._v(" Nível de dificuldade: "),
              ]),
              _c("vs-slider", {
                model: {
                  value: _vm.temperature,
                  callback: function ($$v) {
                    _vm.temperature = $$v
                  },
                  expression: "temperature",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 mt-4" },
        [
          _c("label", { staticClass: "w-full text-left block" }, [
            _vm._v(" Descrição sobre o tema: "),
          ]),
          _c("vs-textarea", {
            ref: "textarea",
            staticClass: "w-full",
            attrs: {
              id: "prompt",
              placeholder:
                "Informe o tema aqui, pode usar uma breve descrição ou um texto mais complexo. Por exemplo: 'Impacto do diabetes na vida adulta e suas limitações'",
            },
            on: { input: _vm.valueChange },
            model: {
              value: _vm.prompt,
              callback: function ($$v) {
                _vm.prompt = $$v
              },
              expression: "prompt",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "grid grid-cols-1 mt-4" }, [
        _c(
          "div",
          { staticClass: "w-full flex gap-2 justify-end" },
          [
            _c(
              "vs-button",
              {
                attrs: { disabled: !_vm.validateForm },
                on: { click: _vm.generate },
              },
              [_vm._v(" Gerar ")]
            ),
            _c(
              "vs-button",
              { attrs: { type: "border" }, on: { click: _vm.cancel } },
              [_vm._v(" Cancelar ")]
            ),
          ],
          1
        ),
      ]),
      _vm._l(_vm.questions, function (question) {
        return _c(
          "div",
          { key: question, staticClass: "grid grid-cols-1 mt-4" },
          [
            _c(
              "vs-checkbox",
              {
                staticClass: "mt-2",
                staticStyle: { "margin-left": "25px" },
                model: {
                  value: question.selected,
                  callback: function ($$v) {
                    _vm.$set(question, "selected", $$v)
                  },
                  expression: "question.selected",
                },
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("base_question.card.action.select"))),
                ]),
              ]
            ),
            question.type === "simple_text"
              ? _c("SimpleText", { attrs: { item: question, mode: "form" } })
              : _vm._e(),
            question.type === "multiple_choice"
              ? _c("MultipleChoice", {
                  attrs: { item: question, mode: "form" },
                })
              : _vm._e(),
            question.type === "check_box"
              ? _c("CheckBox", { attrs: { item: question, mode: "form" } })
              : _vm._e(),
            question.type === "gap"
              ? _c("Gap", { attrs: { item: question, mode: "form" } })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm.questions.length > 0
        ? _c("div", { staticClass: "grid grid-cols-1 mt-4" }, [
            _c(
              "div",
              { staticClass: "w-full flex gap-2 justify-end" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "flex-grow-0 flex-shrink-0",
                    attrs: { icon: "add" },
                    on: {
                      click: function ($event) {
                        return _vm.addAllSelected()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("base_question.modal.add_selected")) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }