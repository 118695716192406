
<template>
  <vx-card class="tour-publication-1">
    <PlanUsageAlert target="participant_questionnaire"/>
    <vx-tour
      name="questionnairePublicationIntroSteps"
      :auto="true"/>

    <div class="grid sm:flex tour-publication-2">
      <div class="w-full sm:w-10/12 break-all text-center sm:text-left flex items-center">
        <label class="mr-4 tour-publication-3">
          <b>{{ $t('link-do-questionario') }}</b>
        </label>
        <div class="tour-questionnaire-publication">{{ shortUrl }}</div>
      </div>
      <div class="w-full sm:w-2/12 flex justify-center sm:justify-start ml-0 sm:ml-4 mt-2 sm:mt-0">
        <vs-button
            type="filled"
            icon="content_copy"
            class="footer-button"
            v-clipboard:copy="shortUrl"
            v-clipboard:success="onSucceddCopy"
            v-clipboard:error="onErrorCopy"
            >{{ $t('copiar') }}</vs-button>
      </div>
    </div>

<!--
    <h5 class="mt-8 border-bottom tour-publication-4">{{ $t('alunos-com-cadastro') }}</h5>

    <div class="grid md:flex items-end">
      <div class="w-full md:w-9/12">
        <label>{{ $t('classe') }}</label>

        <vs-select v-model="selectedClass" class="tour-publication-5">
          <vs-select-item
            v-for="item in classList"
            :key="item"
            :value="item"
            :text="`${item.title} - ${studentsQuantity(item)} Aluno(s)`"
          />
        </vs-select>
      </div>
      <div class="w-full md:w-3/12 flex justify-end md:justify-start ml-0 md:ml-4 mt-2 md:mt-0">
        <vs-button
          @click="addStudents"
          icon="add"
          :disabled="selectedClass === null || studentsQuantity(selectedClass) === 0"
          >{{ $t('adicionar-alunos') }}</vs-button>
      </div>
    </div>

    <div class="vx-row flex items-end mt-4">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <questionnaire-publication-grid
          ref="studentListGrid"
          @selected="changedStudentSelection"
          @remove="removeEmailSent"
          :noDataText="$t('voce-ainda-nao-adicionou-nenhum-participante')"
          type="student"
          :id="content.id"
        />
      </div>
    </div>
-->
    <h5 class="mt-6 border-bottom">{{ $t('convidar-participantes') }}</h5>

    <div class="vx-row flex items-end mt-4">
      <div class="vx-col w-full mb-2">
        <emails-input
          ref="emailsInput"
          @tagsSent="addEmails"
          @importStudents="importStudents"
          buttonText="Adicionar"
          class="tour-publication-6">
        </emails-input>
      </div>
    </div>

    <div class="vx-row flex items-end mt-4">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <questionnaire-publication-grid
          ref="emailListGrid"
          @selected="changedEmailSelection"
          @remove="removeEmailSent"
          noDataText="Você ainda não adicionou nenhum email."
          type="email"
          :id="content.id"/>
      </div>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2">
      <div class="font-bold text-center sm:text-left flex items-center">
        <div v-if="publicated">
          {{
            `Questionário publicado em ${this.$utils.format.dateWithFormat(publicated, 'DD/MM/YYYY - kk:mm:ss')}`
          }}
        </div>
      </div>

      <div class="font-bold flex justify-center sm:justify-end mt-4 sm:mt-0">
        <vs-button
          v-if="!publicated"
          class="tour-publication-7"
          v-permission="'questionnaires.release'"
          icon="send" @click="confirmSend">
          {{ $t('liberar-e-enviar-email') }}
        </vs-button>
        <button-alternates
                iconMain="send"
                v-if="publicated"
                :label="$t('reenviar-convite')"
                buttonClass="px-4"
                :alternatives="[
                  {
                    icon: 'send',
                    label: $t('reenviar-convite-pendente'),
                    color: 'warning',
                    click: () => {confirmSendWithStatus('p')}
                  }
                ]"
                @click-main="confirmSend()"
              />
      </div>
    </div>
  </vx-card>
</template>

<script>
import ClassService from '@/services/api/ClassService'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import EmailsInput from '@/components/EmailsInput'
import QuestionnairePublicationGrid from './QuestionnairePublicationGrid.vue'
const VxTour = () => import('@/components/VxTour.vue')
import ButtonAlternates from '@/components/ButtonAlternates.vue'
import PlanUsageAlert from '@/components/finance/PlanUsageAlert'

export default {
  name: 'QuestionnairePublication',
  components: {
    EmailsInput,
    VxTour,
    QuestionnairePublicationGrid,
    ButtonAlternates,
    PlanUsageAlert
  },
  props: {
    content: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    classService: null,
    contentQuestionnaireService: null,
    classList: [],
    selectedClass: null,
    studentEmails: [],
    activePerson: null,
    studentList: [],
    emailList: [],
    selectAll: false,
    key: 0,
  }),
  computed: {
    shortUrl() {
      return this.content ? this.content.short_url : ''
    },
    studentGrid() {
      return false //this.$refs['studentListGrid']
    },
    emailGrid() {
      return this.$refs['emailListGrid']
    },
    publicated() {
      return this._.get(this.content, 'published_at') || false
    }
  },
  watch: {
    selectAll() {
      this.emailList.forEach(element => {
        element.selected = this.selectAll
      })
    },
    emailList : {
      handler() {
        let selectAll = true
        this.emailList.forEach(element => {
          if (!element.selected) {
            selectAll = false
          }
        })
        this.selectAll = this.emailList.length === 0 ? false : selectAll
      }
    }
  },
  methods: {
    changedStudentSelection(event) {
      this.studentList = event.selection
    },
    changedEmailSelection(event) {
      this.studentEmails = event.selection
    },
    studentsQuantity(classItem) {
      let studentQuantity = 0
      if (!this.isEmpty(classItem)) {
        const classDisciplines = classItem.class_disciplines
        classDisciplines.forEach(classDiscipline => {
          studentQuantity += classDiscipline.class_students.length
        })
      }
      return studentQuantity
    },
    studentId(data) {
      return data.student ? data.student.id : data.id
    },
    personName(data) {
      return data.student
        ? data.student.person.user.name
        : data.person.user.name
    },
    personEmail(data) {
      return data.student
        ? data.student.person.user.email
        : data.person.user.email
    },
    // 0: Not sent, 1: Sent, 2: Sending, 3: Resended
    statusSuccess(data) {
      return data.status === 1
    },

    statusClass(data) {
      return this.statusSuccess(data) ? 'primary-color' : ''
    },
    addStudents() {
      const payload = {
        students: []
      }

      const classDisciplines = this.selectedClass.class_disciplines
      classDisciplines.forEach(classDiscipline => {
        const classStudents = classDiscipline.class_students
        classStudents.forEach((classStudent) => {
          payload.students.push(classStudent.student_id)
        })
      })

      this.createEmailsSent(payload).then(
        (data) => {
          const selection = this._.get(data, 'selection', []) || []
          const messages = this._.get(data, 'messages', []) || []

          if (messages.length > 0) {
            messages.forEach((message) => {
              this.notifyWarning(this.$vs, message, 0)
            })
          }
          //this.studentGrid.selectMore(selection)
        }
      )
    },
    addEmails(list) {
      const formatted = list.map((email) => {
        return email.text
      })

      this.createEmailsSent({
        emails: formatted
      }).then(
        (data) => {
          const selection = this._.get(data, 'selection', []) || []
          const messages = this._.get(data, 'messages', []) || []

          if (messages.length > 0) {
            messages.forEach((message) => {
              this.notifyWarning(this.$vs, message, 0)
            })
          }
          console.log('selection...', selection)
          this.emailGrid.selectMore(selection)
        }
      )
    },
    importStudents(students) {
      console.log('importStudents', students)
      const formatted = students.map((student) => {
        return {
          name: student.name,
          email: student.email,
          create_user: student.create_user
        }
      })

      this.createEmailsSent({
        users_to_import: formatted
      }).then(
        (data) => {
          const selection = this._.get(data, 'selection', []) || []
          const messages = this._.get(data, 'messages', []) || []

          if (messages.length > 0) {
            messages.forEach((message) => {
              this.notifyWarning(this.$vs, message, 0)
            })
          }

          this.emailGrid.reload()
          this.emailGrid.selectMore(selection)
        }
      )
    },
    createEmailsSent(payload) {
      this.$vs.loading()
      return this.contentQuestionnaireService.createEmailsSent(this.content.id, {
        payload: payload
      }).then(
        (data) => {
          this.$refs['emailsInput'].clear()
          this.$vs.loading.close()

          this.reloadGrids()
          return data
        },
        (error) => {
          this.$vs.loading.close()
          console.error(error)
        }
      )
    },
    reloadGrids(studentsGrid = true, emailsGrid = true) {
      if (studentsGrid) {
        //this.studentGrid.reload()
      }
      if (emailsGrid) {
        this.emailGrid.reload()
      }
    },
    addStudentEmail(email) {
      const found = this.emailList.find((element) => {
        return element.email === email
      })

      if (!found) {
        this.emailList.push({
          email: email,
          status: 0,
          selected: true,
        })
      }
    },
    removeEmailSent(emailSent) {
      this.$vs.loading()
      this.contentQuestionnaireService.destroyEmailSent(this.content.id, emailSent.id).then(
        (data) => {
          this.$vs.loading.close()
          const hasStudentId = emailSent.student_id !== null
          this.reloadGrids(hasStudentId, !hasStudentId)
        },
        (error) => {
          this.$vs.loading.close()
          console.error(error)
        }
      )
    },
    confirmSend() {
      if (this.validateQuestions()) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'success',
          title: this.$t('confirmacao'),
          acceptText: this.$t('sim-enviar'),
          cancelText: this.$t('nao'),
          text: this.$t('tem-certeza-que-deseja-enviar-para-todos-selecionados'),
          accept: () => {
            this.send()
          },
        })
      }
    },
    confirmSendWithStatus(status){
      this.$vs.dialog({
          type: 'confirm',
          color: 'success',
          title: this.$t('confirmacao'),
          acceptText: this.$t('sim-enviar'),
          cancelText: this.$t('nao'),
          text: this.$t('tem-certeza-que-deseja-enviar-para-todos-pendentes'),
          accept: () => {
            this.resendInvitePending()
          },
        })
    },
    send(statusParam) {
      const studentList = this.studentList
      const emailList = this.studentEmails

      const payload = {
        status: statusParam,
        questionnaire_emails: studentList.concat(emailList)
      }

      this.$vs.loading()
      this.contentQuestionnaireService.sendEmailToAll(this.content.id, payload).then(
          (response) => {
            this.$vs.loading.close()

            const emptyPublish = this.isEmpty(this.content.published_at)

            const hasEmails = !this.isEmpty(response.emails_sent) && (studentList.length > 0 || emailList.length > 0)

            if (emptyPublish) {
              this.notifySuccess(
                this.$vs,
                this.$t('questionario-liberado-com-sucesso'),
                3000,
                'center-top'
              )
            } else if (!emptyPublish && !hasEmails) {
              this.notifyWarning(
                this.$vs,
                this.$t('este-questionario-ja-foi-liberado'),
                5000,
                'center-top'
              )
            }

            if (hasEmails) {
              const message =
                studentList.length + emailList.length === 1
                  ? this.$t('o-email-foi-enviado-com-sucesso')
                  : this.$t('os-emails-foram-encaminhados')
              this.notifySuccess(this.$vs, message, 3000, 'center-top')
            }

            this.$emit('published', _.get(response, 'published_at'))

            //this.studentGrid.clearSelection()
            this.emailGrid.clearSelection()
            this.reloadGrids(studentList.length > 0, emailList.length > 0)
          },
          (error) => {
            this.notifyErrors(this.$vs, error, 8000)
            this.$vs.loading.close()
          }
        )

    },
    resendInvitePending(){
      this.contentQuestionnaireService.resendInvitePending(this.content.id).then(
        response => {
          this.notifySuccess(this.$vs, this.$t('os-emails-foram-encaminhados'), 3000, 'center-top')
          this.emailGrid.clearSelection()
          this.reloadGrids()
        },
        error => {this.notifyError(this.$vs, this.$t('erro-ao-processar-esta-requisicao'))}
      ).finally(() => {this.$vs.loading.close()})
    },
    onSucceddCopy() {
      this.notifySuccess(this.$vs, `${this.$t('copiado')}!`, 2000, 'center-top')
      if (this.content.published_at === null) {
        this.notifyWarning(
          this.$vs,
          this.$t('nao-esqueca-de-liberar-o-questionario-an'),
          5000,
          'center-top'
        )
      }
    },
    onErrorCopy() {
      this.notifyError(this.$vs, this.$t('nao-foi-possivel-copiar'))
    },
    // Check if has some question without correct selection
    validateQuestions() {
      let questionWithoutSelection = false
      let questionWithoutScore = false
      const questions = this.isEmpty(this.content.data) ? null : JSON.parse(this.content.data)

      if (questions === null || questions.fields.length === 0) {
        this.notifyWarning(this.$vs, this.$t('nao-possivel-liberar-um-questionario-sem'), 2000)
        return false
      }

      if (!this.isEmpty(questions) && !this.isEmpty(questions.fields)) {
        questions.fields.forEach(question => {
          if (question.type === 'section') return
          const score = _.get(question, 'data.score')
          if (question.type === 'multiple_choice' || question.type === 'check_box') {
            const options = question.data.options
            if (!this.isEmpty(options)) {
              let noneQuestionSelected = false
              options.forEach(option => {
                if (option.selected) {
                  noneQuestionSelected = true
                }
              })

              if (!noneQuestionSelected) {
                questionWithoutSelection = true
              }
            }
          }

          if (!score || parseFloat(score) === 0) {
            questionWithoutScore = true
          }
        })
      }

      if (questionWithoutSelection && questions.fields.length > 0) {
        this.notifyWarning(this.$vs, this.$t('ha-questoes-sem-a-resposta-correta-selec'), 10000)
      }

      if (questionWithoutScore && questions.fields.length > 0) {
        this.notifyWarning(this.$vs, this.$t('ha-questoes-sem-a-pontuacao-definida'), 10000)
      }

      return true
    }
  },
  beforeMount() {
    this.activePerson = this.$store.getters.activePerson
    this.classService = ClassService.build(this.$vs)
    this.contentQuestionnaireService = ContentQuestionnaireService.build(
      this.$vs
    )

    const emailsSent = this.content.emails_sent
    if (emailsSent && emailsSent.length > 0) {
      emailsSent.forEach((emailSent) => {
        emailSent.status = 1
        emailSent.selected = false
        if (emailSent.student_id === null) {
          this.emailList.push(emailSent)
        } else {
          this.studentList.push(emailSent)
        }
      })
    }
  },
  mounted() {
    if (this.$acl.hasPermission('classes.show')) {
      this.classService.teacherClasses(this.activePerson.id).then((response) => {
        this.classList = response
        if (!this.isEmpty(this.classList) && this.classList.length === 1) {
          this.selectedClass = this.classList[0]
        }
      })
    }

    if (this.socketEnabled()) {

      this.$root.$on('notification.questionnaire_link.sent', (notification) => {
        this.reloadGrids()
      })

      window.Echo.private(
        'Illuminate.Notifications.AnonymousNotifiable.'
      ).notification((notification) => {
        this.reloadGrids()
      })
    }

    this.$root.$on('QUESTIONNAIRE_CONTENT_UPDATED', (content) => {
      this.content = content
    })
  },
  beforeDestroy() {
    if (window.Echo) {
      window.Echo.leaveChannel('private-Illuminate.Notifications.AnonymousNotifiable.')
    }
    this.destroyChilds(this)
  }
}
</script>

<style scoped>
.con-select {
  width: 100%;
}

.border-bottom {
  border-bottom: 1px solid #dadce0;
  padding-bottom: 5px;
}
</style>
