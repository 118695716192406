import Rest from '../Rest'

export default class ContentQuestionnaireTemplateService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/content_questionnaire_templates'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }
  
}
